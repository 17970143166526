import React, {useEffect, useState, useContext} from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlogPost from "./blog-post";
import BlogSidebar from "./blog-sidebar";
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { MenuContext } from "@/context/menu-context";

const isBrowser = typeof window !== "undefined"

const BlogDetails = () => {
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
        newsBannerImg
        env {
          APISERVER
          TENANTID
        }
      }
    }
  `)

 
  const id = isBrowser ? window.location.pathname.slice(window.location.pathname?.lastIndexOf('/') + 1) : null
    
  const { contextTags, setContextTags } = useContext(MenuContext);

  const { env } = data.pharmacy

  const [news, setNews] = useState([])
  const [categories, setCategories] = useState([])
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false)
  function adaptNews(newsData) {
    let data = []
    newsData.map((news, index) => {
      data.push({
        index,
        pk: news.id,
        img: news.image,
        badge: news.image,
        title: news.title,
        subtitle: news.headline,
        slug: news.slug,
        author: "",
        body: news.content,
        linksTo: "/about",
        detailPage: `/novedades/${news.id}`,
        date: news.date_published,
        tags: news.tags.map(tag => tag.name),
        categorie: news.category.name,
      })
      return null
    }
      
      
    )
    return data
  }

  useEffect(() => {
    setFetching(true);
    fetch(`${env.APISERVER}/core/noticias/get_data/?tenant=${env.TENANTID}`)
      .then(response => response.json())
      .then(resultData => {
        setNews(adaptNews(resultData['news']))
        setCategories(resultData['categories'])
        setContextTags(resultData['tags'])
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false)
        setError(true)
      })
  }, [env.APISERVER, env.TENANTID, setContextTags])

  const posts = news?.filter(newsItem => newsItem.pk === Number(id)).map((newsItem, idx) => {
    const date = new Date(newsItem.date)
    return {
      index: newsItem.index,
      id: newsItem.pk,
      image: newsItem.img,
      title: newsItem.title,
      text: newsItem.subtitle,
      date: `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`,
      url: newsItem.url,
      tags: newsItem.tags,
      category: newsItem.category,
        body: newsItem.body,

    }
  }
  )

  if (fetching)
    return (
      <div className="container">
        <div className="row">
          <div
            style={{ marginBottom: "2.5rem", marginTop: "2.5rem", textAlign: "center" }}
          >
            <div className="spinner-border">

            </div>
            <h5>
              <span style={{ color: "grey" }}>Cargando las novedades</span>
            </h5>
          </div>
        </div>
      </div>
    )
  
  if (error)
    return (
      <div className="container">
        <div className="row">
          <div
            style={{ marginBottom: "2.5rem", textAlign: "center" }}
          >
            <h5>
              <span style={{ color: "grey" }}>
                Se produjo un error al cargar la lista de novedades.
              </span>
            </h5>
            <Link to="/">
              <span>Volver al inicio</span>
            </Link>
          </div>
        </div>
      </div>

    )
  
  return (
    <section className="blog_details_section section_padding mb-5">
      <Container>
        <Row className={''}>
          <Col lg={8} className='d-flex flex-wrap'>
            {posts?.length > 0 ? posts.map((post, idx) =>
              <div  key={`post-${post.title}`}>
                <BlogPost post={post} details={true} nextPost={news[Number(post.index) - 1]} prevPost={news[Number(post.index) + 1]}/>
              </div>
            )
              :
              <div className="container">
                <div className="row">
                  <div
                    style={{ marginBottom: "2.5rem", textAlign: "center" }}
                  >
                    <h5>
                      <span style={{ color: "grey" }}>
                        La novedad solicitada no existe
                      </span>
                    </h5>
                    <Link to="/">
                      <span>Volver al inicio</span>
                    </Link>
                  </div>
                </div>
              </div>
          }
          </Col>
          <Col lg={4} className={'fixed'}>
            <BlogSidebar news={news} categories={categories} tags={contextTags} tagsAreLinks={true}/>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BlogDetails;
